main {
  display: grid;
  grid-template-columns: 4.1rem 1fr;
}

.wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
}

.fs-7 {
  font-size: "14px";
}
