.inbox-contact-search-input {
  font-size: 23px;
  font-weight: 600;
  border: none;
  border-bottom: 1px gray solid;
  box-shadow: none;
  width: 100%;
  padding: 12px 0px 15px 0px;
}

.inbox-contact-phone-number {
  font-size: 13.5px;
}
