.inbox-row {
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
}

.inbox-row-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inbox-row-date {
  font-size: 12px;
  justify-self: end;
  align-self: center;
}

.inbox-row-new-message-indicator {
  position: absolute;
  font-size: 10px;
  top: 13px;
  left: 7px;
}

.inbox-row-select {
  position: absolute;
  top: 50%;
  left: 7px;
}

.inbox-detail {
  width: 500px;
}

.inbox-message-type {
  font-size: 13px;
}

.inbox-input-control {
  width: 100%;
  border: none;
  resize: none;
  padding: 0.5rem;
  border-bottom: 1px white;
}

.inbox-row-snippet {
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.inbox-wrapper {
  display: flex;
  overflow: hidden;
}

.inbox-row:hover {
  background-color: var(--light-gray);
  cursor: pointer;
}

.inbox-row-active:hover {
  background-color: var(--bs-primary);
  cursor: pointer;
}

.inbox-row-active {
  background-color: var(--bs-primary);
  color: white;
}

.inbox-row-badge {
  background-color: rgb(225, 228, 235);
  color: rgb(73, 73, 73);
  padding: 2px 5px;
  font-size: 13px;
  border-radius: 1px;
  margin-right: 5px;
}

.inbox-active-tab {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 50%;
  background: black;
}

.inbox-active-tab--end {
  left: 50%;
}

.inbox-row-status {
  height: 19px;
  width: 19px;
  background-color: black;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.inbox-row-status i {
  font-size: 13px;
  color: white;
}

.disable-input-hover:hover {
  background-color: unset !important;
  color: unset !important;
}

.inbox-list-item-active {
  color: white !important;
  /*background-color: #55647c !important;*/
}

.inbox-list-item-action-btn {
  background-color: #535353 !important;
  padding: 2px 0px;
}

.inbox-row-customer-care {
  background-color: var(--bs-success-bg-subtle) !important;
}

.inbox-row-customer-care-active {
  background-color: var(--bs-success) !important;
}

.inbox-list-collapsed {
  width: 45px !important;
}
