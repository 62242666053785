.ls-button {
  padding: 0 12px;
  display: inline-block;
  border: 1px solid #999;
  border-radius: 3px;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: normal;
  line-height: 32px;
  white-space: nowrap;
  transition:
    border 0.3s,
    background 0.3s,
    color 0.3s;
  background: #888;
  border-color: #888;
}

.ls-button-error {
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 16px;
  border-radius: 0px 4px 4px 0px;
}

.ls-button-success {
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 16px;
  border-radius: 0px 4px 4px 0px;
}
