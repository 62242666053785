.location-dropdown-btn {
  background-color: transparent;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid rgb(242, 242, 242);
  box-shadow: none;
  max-height: 50px;
}

.location-dropdown-btn-dark {
  border: 1px solid black !important;
  color: black !important;
}

@media (max-width: 575px) {
  .location-dropdown-btn {
    max-width: 150px;
  }
}

.location-dropdown-btn i {
  position: absolute;
}

.location-dropdown ul {
  max-height: 80vh;
  overflow: scroll;
}

@media (max-width: 575px) {
  .location-dropdown-menu {
    width: 100% !important;
  }
}
