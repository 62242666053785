.sms-card {
  padding: 11px 15px;
  max-width: 75%;
  border-radius: 30px;
  display: inline-block;
  overflow-wrap: anywhere;
}

@media (min-width: 767.98px) {
  .sms-card {
    max-width: 430px;
  }
}

.sms-card--from-shop {
  background-color: var(--bs-primary);
  color: white;
  text-align: left;
  border-bottom-right-radius: 5px;
}

.sms-card--to-shop {
  background-color: rgb(235, 235, 235);
  border-bottom-left-radius: 2px;
}

.sms-card-info {
  font-size: 13px;
  font-weight: 500;
}

.mms-media-img {
  max-width: 270px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid #f5f5f5 !important;
}

.mms-placeholder {
  height: 400px;
  width: 200px;
}

@media (max-width: 300px) {
  .mms-media-img {
    max-width: 210px;
  }
}

.mms-media-container {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mms-downloadable-card {
  width: 330px;
}

@media (max-width: 300px) {
  .mms-downloadable-card {
    width: 210px;
  }
}

.message-error {
  font-size: 13px;
  max-width: 450px;
}

.review-request-card-link {
  max-width: 300px !important;
}
