.card-invoice {
  width: 275px;
}

.card-invoice-amount {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
}

.card-invoice-description {
  margin: 2px;
  padding: 0px;
  font-size: 15px;
}

.card-invoice a {
  font-size: 14px;
}

.card-invoice-expired {
  font-size: 15px;
}
