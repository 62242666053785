.card-website-widget {
  max-width: 300px;
  border-radius: 30px;
  border-bottom-left-radius: 5px;
  padding: 5px;
}

.card-website-widget img {
  height: 40px;
  width: 40px;
}
