.main-menu .dropdown-item {
  padding: 10px 8px;
}

.navbar-nav li {
  font-weight: 500 !important;
}

.nav-link-active {
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-bottom: 3px var(--bs-primary) solid;
  width: 100%;
}

.nav-link {
  position: relative;
  border-bottom: 3px transparent solid;
}

.main-menu li i {
  margin-right: 10px;
}
